<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <signup-card>
            <h2 class="title text-center" slot="title">Registro</h2>
            <div
              class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto"
              slot="content-left"
            >
              <div
                class="info info-horizontal"
                v-for="item in contentLeft"
                :key="item.title"
              >
                <div :class="`icon ${item.colorIcon}`">
                  <md-icon>{{ item.icon }}</md-icon>
                </div>
                <div class="description">
                  <h4 class="info-title">{{ item.title }}</h4>
                  <p class="description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto"
              slot="content-right"
            >
              <ValidationProvider
                name="form.nomeCompleto"
                :rules="{
                  regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                  min: 3,
                  max: 250,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>person</md-icon>
                  <label>Nome Completo</label>
                  <md-input
                    v-model="form.nomeCompleto"
                    type="text"
                    :maxlength="250"
                    class="uppercase"
                  >
                  </md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>

              <ValidationProvider
                name="cpf"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>assignment_ind</md-icon>
                  <label>CPF</label>
                  <md-input
                    v-model="form.cpfUsuario"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>
              <ValidationProvider
                name="form.email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>email</md-icon>
                  <label>Email</label>
                  <md-input v-model="form.email" type="email"> </md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>

              <ValidationProvider
                name="form.senha"
                :rules="{
                  regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                  min: 8,
                  required: true,
                  confirmed: 'form.confirmacao'
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>Senha</label>

                  <md-input
                    ref="senha"
                    v-model="form.senha"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>

              <ValidationProvider
                vid="form.confirmacao"
                name="form.confirmacao"
                rules="min:8|required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>Confirmar Senha</label>

                  <md-input
                    ref="confirmacao"
                    v-model="form.confirmacao"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>
              <div class="md-layout-item">
                <ValidationProvider
                  name="Termos e Condições"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-checkbox
                    v-model="form.termosCondicoes"
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >Estou de acordo com os termos e condições.
                  </md-checkbox>
                  <br />
                  <a
                    style="font-weight: 500;"
                    target="_blank"
                    href="/files/TermosCondicoesUso-Sindcalculos.pdf"
                    >Visualizar Termos e condições(PDF)</a
                  >
                  <br />
                  <slide-y-down-transition>
                    <span
                      v-show="failed"
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </slide-y-down-transition>
                </ValidationProvider>
              </div>
              <div class="md-layout-item button-container">
                <md-button
                  href
                  class="md-success md-round mt-4"
                  slot="footer"
                  type="submit"
                  >Registrar</md-button
                >
              </div>
            </div>
          </signup-card>
        </form>
      </ValidationObserver>
    </div>
    <template>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 style="color: red;" class="modal-title">
            {{ modalTitle }}<br /><small style="color: green;"
              >Gostaria de Solicitar ao nosso time ?</small
            >
          </h4>

          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <ValidationObserver ref="observer2" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitSolicitacao)">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="Nome Completo"
                    :rules="{
                      regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                      min: 3,
                      max: 150,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <md-icon>person</md-icon>
                      <label>Nome Completo</label>
                      <md-input
                        v-model="form2.nomeCompleto"
                        type="text"
                        :maxlength="150"
                        class="uppercase"
                      >
                      </md-input>
                    </md-field>
                    <slide-y-down-transition>
                      <span
                        v-show="failed"
                        id="error"
                        class="block text-red-600 text-xs absolute bottom-0 left-0"
                        v-if="errors[0]"
                        >{{ errors[0] }}
                      </span>
                    </slide-y-down-transition>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <md-icon>email</md-icon>
                      <label>Email</label>
                      <md-input v-model="form2.email" type="email"> </md-input>
                    </md-field>
                    <slide-y-down-transition>
                      <span
                        v-show="failed"
                        id="error"
                        class="block text-red-600 text-xs absolute bottom-0 left-0"
                        v-if="errors[0]"
                        >{{ errors[0] }}
                      </span>
                    </slide-y-down-transition>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider
                    name="telefone"
                    :rules="{
                      required: true,
                      regex: /^((\(([1-9][0-9])\))|(([1-9][0-9])))(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Telefone</label>
                      <md-input
                        v-model="form2.telefone"
                        type="text"
                        :maxlength="14"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <br />
                  <md-button type="submit" class="md-raised md-success mt-4">
                    ENVIAR
                  </md-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
        <template slot="footer"> </template>
      </modal>
    </template>
  </div>
</template>
<script>
import { SignupCard } from "@/components";
import { mapActions, mapGetters } from "vuex";
import { SlideYDownTransition } from "vue2-transitions";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { validarCPF } from "@/pages/Dashboard/js/validarCPF";
import { Modal } from "@/components";
import { telefoneMask } from "@/pages/Dashboard/js/telefoneMask";
import Vue from "vue";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex =
      "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial.";
  }
}
const fields = {
  cpf: {
    regex: "O campo CPF é inválido !"
  },
  "form.nomeCompleto": {
    regex: "O Nome Completo possuí caracters não permitidos."
  },
  "Nome Completo": {
    regex: "O Nome Completo possuí caracters não permitidos."
  },
  telefone: {
    regex:
      "O telefone deve conter dd e numero no formato (DD)99999-9999 ou (DD)9999-9999."
  }
};

for (let key in pt_br.messages) {
  extend(key, rules[key]);
}

Vue.set(pt_br, "fields", fields);

localize("pt_BR", pt_br);

export default {
  //016.413.356-96
  components: {
    SlideYDownTransition,
    SignupCard,
    Modal
  },
  computed: {
    checked_rule() {
      return {
        required: true,
        regex: /^((\(([1-9][0-9])\))|(([1-9][0-9])))(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
      };
    }
  },
  data() {
    return {
      form: {
        nomeCompleto: "",
        cpfUsuario: "",
        email: "",
        senha: "",
        confirmacao: "",
        termosCondicoes: ""
      },
      form2: {
        nomeCompleto: "",
        email: "",
        telefone: ""
      },
      boolean: false,
      modalTitle: null,
      classicModal: false,
      contentLeft: [
        {
          colorIcon: "icon-danger",
          icon: "code",
          title: "Quem pode se Registrar",
          description:
            "Para realizar o Registro seu cpf deve ter sido previamente cadastrado pelo SindCalculos devido alguma relação existem entre você e a empresa."
        },
        {
          colorIcon: "icon-info",
          icon: "group",
          title: "Credenciais",
          description:
            "Após a o preenchimento do cadastro um email será envido para você confirmar seu email e cadastro."
        }
      ]
    };
  },
  methods: {
    ...mapActions("auth", ["ActionRegistro", "ActionSolicitacaoCadastro"]),
    async submit() {
      try {
        let form = this.form;
        let valido = validarCPF(this.form.cpfUsuario);

        if (valido !== true) {
          this.$refs.observer.setErrors({
            cpf: ["O campo CPF é inválido !"]
          });
        } else {
          await this.ActionRegistro({ form }).then(response => {
            if (response.status === 200) {
              this.mensagem = response.body.mensagem;
              if (!response.body.mensagem.includes("Erro")) {
                if (
                  response.body.mensagem.includes("não") ||
                  response.body.mensagem.includes("inválido")
                ) {
                  Swal.fire({
                    //title: "Sucesso",
                    icon: "warning",
                    text: this.mensagem,
                    customClass: {
                      confirmButton: "md-button md-success btn-fill"
                    },
                    buttonsStyling: false
                  });
                } else {
                  Swal.fire({
                    //title: "Sucesso",
                    icon: "success",
                    text:
                      "Um email com um link foi enviado para validar seu login.",
                    customClass: {
                      confirmButton: "md-button md-success btn-fill"
                    },
                    buttonsStyling: false
                  });
                }
              } else {
                if (this.mensagem.includes("não consta")) {
                  this.classicModal = true;
                  this.modalTitle = this.mensagem
                    .toString()
                    .replace("Erro:", "");
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    text: this.mensagem,
                    customClass: {
                      confirmButton: "md-button md-danger btn-fill"
                    },
                    buttonsStyling: false
                  });
                }
              }
              this.resetForm();
            }
          });
        }

        //let response = await this.ActionDoLogin(this.form);
        //let response2 = await this.ActionLoadSession();
        // .then(this.$router.push({ name: "Home" }))
        // .catch(this.$router.push({ name: "login" }));
      } catch (err) {
        this.handleError(err);
      }
    },
    async submitSolicitacao() {
      try {
        let form = this.form2;
        await this.ActionSolicitacaoCadastro({ form }).then(response => {
          if (response.status === 200) {
            this.mensagem = response.body.mensagem;
            if (!response.body.mensagem.includes("Erro")) {
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: this.mensagem,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false
              });
              this.classicModal = false;
            } else {
              Swal.fire({
                title: "Erro!",
                icon: "error",
                text: this.mensagem,
                customClass: {
                  confirmButton: "md-button md-danger btn-fill"
                },
                buttonsStyling: false
              });
            }
            this.form2 = {
              nomeCompleto: "",
              email: "",
              telefone: ""
            };
          }
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    resetForm() {
      this.form = {
        nomeCompleto: "",
        cpfUsuario: "",
        email: "",
        senha: "",
        confirmacao: "",
        termosCondicoes: ""
      };
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    classicModalHide: function() {
      this.classicModal = false;
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 400) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  watch: {
    "form.cpfUsuario": function(val, oldVal) {
      let cpf2 = cpfMask(val);
      this.form.cpfUsuario = cpf2;
    },
    "form2.telefone": function(val, oldVal) {
      let numero = telefoneMask(val);
      this.form2.telefone = numero;
    }
  }
};
</script>
<style>
.info {
  max-width: 90%;
  padding: 10px 0 10px;
}

.md-card-signup .md-card-content {
  padding: 0px 10px 0px 10px;
}

.md-checkbox .md-checkbox-label {
  color: #000;
}

.uppercase {
  text-transform: uppercase;
}

#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
</style>
